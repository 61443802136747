// Supportive colors
export const colors = [
  "red",
  "amber",
  "orange",
  "yellow",
  "lime",
  "green",
  "teal",
  "emerald",
  "cyan",
  "lightBlue",
  "indigo",
  "violet",
  "purple",
  "fuchsia",
  "pink",
  "rose",
] as const;
