import { Button, Flex, Text } from "components/Base";
import SEO from "global/SEO";
import { IoMdHome } from "react-icons/io";
import { Link } from "react-router-dom";

function NotFoundPage() {
  return (
    <>
      <SEO />
      <Flex
        direction="column"
        w="100%"
        h="100vh"
        align="center"
        justify="center"
        gap="20px"
      >
        <Text align="center" as="h1" size="$6xl" fontWeight="bold">
          404
        </Text>
        <Text align="center" width="400px" height="2">
          Halaman yang anda cari tidak ditemukan, silahkan kembali ke halaman
          utama
        </Text>
        <Link to="/">
          <Button type="primary" icon={<IoMdHome />}>
            Kembali ke home
          </Button>
        </Link>
      </Flex>
    </>
  );
}

export default NotFoundPage;
